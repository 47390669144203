import React, { useState, useEffect, useRef } from "react";
import logo from "../../assets/gfx/logo.svg";
import { ReactSVG } from "react-svg";
import { useIntl, changeLocale, Link, navigate } from "gatsby-plugin-intl";
import { connect } from "react-redux";
import {
	appUISidenavToggle,
	appUIDropdownMenuToggle,
	appUIPopupLoginShow,
	appRedirect,
	appBootGet,
	appUIErrorsShow,
} from "../../redux/app/actions";
import { authLogout } from "../../redux/auth/actions";
import Geosuggest from "react-geosuggest";
import langES from "../../assets/gfx/lang-es.svg";
import langEN from "../../assets/gfx/lang-en.png";
import langFR from "../../assets/gfx/lang-fr.svg";
import langPT from "../../assets/gfx/lang-pt.svg";
import styled from "styled-components";
import Tools from "../../classes/tools";

const LangButton = styled.button`
	padding: 0;
	margin: 0 0 0 10px;
	border: none;
	background: transparent;
	cursor: pointer;
	> div {
		width: 16px;
	}
	> img {
		width: 16px;
	}
`;

const Header = ({
	sideNav,
	appUISidenavToggle,
	appUIDropdownMenuToggle,
	dropdownMenu,
	appUIPopupLoginShow,
	loggedIn,
	authResolved,
	authLogout,
	appRedirect,
	usuario,
	appBootGet,
	geo,
	onGeoLocationChange,
	appUIErrorsShow,
	s,
}) => {
	const intl = useIntl();

	const [headerScrolledClass, setHeaderScrolledClass] = useState("");

	useEffect(() => {
		window.addEventListener("scroll", () => {
			if (window.scrollY >= 300) {
				setHeaderScrolledClass("scrolled");
			} else {
				setHeaderScrolledClass("");
			}
		});
	}, []);

	const crearAnuncio = () => {
		if (loggedIn) {
			if (sideNav) appUISidenavToggle();
			appRedirect({ to: usuario.completo ? `/crearanuncio` : `/editarcuenta` });
		} else appUIPopupLoginShow();
	};

	const setLang = (language) => {
		appBootGet(language);
		changeLocale(language);
	};

	const geoSuggestRef = useRef(null);

	const onGeoSuggestSelect = (suggest) => {
		if (suggest) {
			const { lat, lng } = suggest.location;
			const { description } = suggest;
			onGeoLocationChange({ lat, long: lng, descripcion: description });
		}
	};

	const goAnuncios = () => {
		if (!geo.lat || !geo.long || !geo.descripcion) {
			appUIErrorsShow(["Elige una ubicación para encontrar tu tabla."]);
		} else {
			navigate(
				`/anuncios/mode/geo/lat/${geo.lat}/long/${geo.long}/geo_descripcion/${geo.descripcion}/orden/distancia`
			);
		}
	};

	return (
		<header className={`black-bg-01 ${sideNav && `nav-menu-opened`} ${headerScrolledClass}`}>
			<div className="wrapper">
				<Link to="/" className="header-logo" onClick={sideNav ? appUISidenavToggle : undefined}>
					<img src={logo} alt={Tools.convertString(s.header?.home)} />
					<img src="/img/mini-logo.svg" className="mini-logo show-lg" alt={Tools.convertString(s.header?.home)} />
				</Link>
				<div
					onClick={appUISidenavToggle}
					className="nav-open-button hide-lg"
					onKeyDown={appUISidenavToggle}
					role="button"
					tabIndex="0"
				>
					<span className="line-01 white-bg"></span>
					<span className="line-02 white-bg"></span>
				</div>
				<div className={`header-menu ${sideNav && `show`}`}>
					<form
						onSubmit={(e) => {
							e.preventDefault();
						}}
					>
						<div className="formWrapper">
							<ReactSVG src="/img/location.svg" />
							<Geosuggest
								ref={geoSuggestRef}
								placeholder={Tools.convertString(s.home?.searchlocation_placeholder)}
								initialValue={geo.descripcion}
								onSuggestSelect={onGeoSuggestSelect}
								autoActivateFirstSuggest={true}
								autoCapitalize="true"
								autoComplete="new-password"
								autoCorrect="false"
								autoSave="false"
							/>
						</div>
						<button type="button" className="button redButton" onClick={goAnuncios}>
							{Tools.convertString(s.home?.searchlocation_button)}
						</button>
					</form>
					<nav className="blue-bg">
						<ul className="box">
							<li className="nav-desktop">
								<ul className="wrapper-nav">
									<li className={`dropdown-menu ${dropdownMenu && `show`}`}>
										<span className="dropdown-button black-text-01 hide-lg" onClick={appUIDropdownMenuToggle}>
											{Tools.convertString(s.header?.about)}
										</span>
										<ul>
											<li>
												<Link
													to="/filosofia"
													className="black-text-01"
													onClick={sideNav ? appUISidenavToggle : undefined}
												>
													{Tools.convertString(s.header?.filosofia)}
												</Link>
											</li>
											<li>
												<Link
													to="/porquealquilar"
													className="black-text-01"
													onClick={sideNav && appUISidenavToggle}
												>
													{Tools.convertString(s.header?.porquealquilar)}
												</Link>
											</li>
											<li>
												<Link
													to="/porquecompartir"
													className="black-text-01"
													onClick={sideNav && appUISidenavToggle}
												>
													{Tools.convertString(s.header?.porquecompartir)}
												</Link>
											</li>
											{/* <li>
													<Link
														to="/hoteles"
														className="black-text-01"
														onClick={sideNav && appUISidenavToggle}
													>
														{Tools.convertString(s.header?.hoteles)}
													</Link>
												</li> */}
										</ul>
									</li>
									<li>
										<ul>
											{/* logout aparece logueado */}
											{loggedIn && authResolved && (
												<li className="show-lg">
													<a
														className="red-text logout uu"
														onClick={() => {
															authLogout(usuario);
															if (sideNav) appUISidenavToggle();
														}}
													>
														{Tools.convertString(s.header?.logout)}
													</a>
												</li>
											)}
											{/* Mi cuenta aparece logueado */}
											{loggedIn && (
												<li className="account-link">
													<Link
														to="/micuenta"
														className="black-text-01"
														onClick={sideNav ? appUISidenavToggle : undefined}
													>
														{Tools.convertString(s.header?.micuenta)}
													</Link>
												</li>
											)}
											{!loggedIn && authResolved && (
												<li className="show-lg ee2">
													<a className="black-text-01 logout" onClick={appUIPopupLoginShow}>
														{Tools.convertString(s.header?.login)}
													</a>
												</li>
											)}
											<li>
												<Link
													to="/contacto"
													className="black-text-01"
													onClick={sideNav ? appUISidenavToggle : undefined}
												>
													{Tools.convertString(s.header?.contacto)}
												</Link>
											</li>
											<li>
												<Link to="/faqs" className="black-text-01" onClick={sideNav && appUISidenavToggle}>
													{Tools.convertString(s.header?.faqs)}
												</Link>
											</li>
											<li>
												<LangButton onClick={() => setLang("es")}>
													<ReactSVG src={langES} />
												</LangButton>
												<LangButton onClick={() => setLang("en")}>
													<img src={langEN} />
												</LangButton>
												<LangButton onClick={() => setLang("pt")}>
													<ReactSVG src={langPT} />
												</LangButton>
												{/* <LangButton onClick={() => setLang("fr")}>
													<ReactSVG src={langFR} />
												</LangButton> */}
											</li>
										</ul>
									</li>
								</ul>
							</li>
							{/* <li>
									<Link to="/playas" className="white-text">
										{Tools.convertString(s.header?.zonas)}
									</Link>
								</li> */}
							<li className="login-button login">
								{authResolved && loggedIn && (
									<Link
										to={usuario.completo ? "/crearanuncio" : "/editarcuenta"}
										className="white-text"
										onClick={sideNav ? appUISidenavToggle : undefined}
									>
										{Tools.convertString(s.header?.comparte)}
									</Link>
								)}
								{authResolved && !loggedIn && (
									<a className="white-text" onClick={crearAnuncio}>
										{Tools.convertString(s.header?.comparte)}
									</a>
								)}
							</li>
						</ul>
						<ul className="box login hide-lg">
							{authResolved && !loggedIn && (
								<li className="login-button">
									<a className="white-text" onClick={appUIPopupLoginShow}>
										{Tools.convertString(s.header?.login)}
									</a>
								</li>
							)}
							{authResolved && loggedIn && (
								<li className="logout">
									<a
										className="white-text"
										onClick={() => {
											authLogout(usuario);
											if (sideNav) appUISidenavToggle();
										}}
									>
										{Tools.convertString(s.header?.logout)}
									</a>
								</li>
							)}
							<li className="ig">
								<a
									href="https://www.instagram.com/shareyourboard/"
									target="_blank"
									className="white-text"
									rel="noopener noreferrer"
									onClick={sideNav ? appUISidenavToggle : undefined}
								>
									{Tools.convertString(s.header?.instagram)}
								</a>
							</li>
						</ul>
					</nav>
				</div>
			</div>
		</header>
	);
};

const mapStateToProps = (state) => {
	const { sideNav } = state.app.ui;
	const { dropdownMenu } = state.app.ui;
	const { loggedIn, resolved: authResolved, usuario } = state.auth;
	const { geo } = state.app;
	return {
		sideNav,
		dropdownMenu,
		loggedIn,
		authResolved,
		usuario,
		geo,
		s: state.app.boot.strings,
	};
};

const mapDispatchToProps = {
	appUISidenavToggle,
	appUIDropdownMenuToggle,
	appUIPopupLoginShow,
	authLogout,
	appRedirect,
	appBootGet,
	appUIErrorsShow,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
