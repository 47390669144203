import React, { useState } from "react";
import { connect } from "react-redux";
import { appUIPopupLoginShow, appUIPopupLoginHide, appUIErrorsShow } from "../../redux/app/actions";
import { authLocalLoginInit, authSocialLoginInit } from "../../redux/auth/actions";
import GoogleLogin from "react-google-login";
import { useIntl, Link } from "gatsby-plugin-intl";
import Tools from "../../classes/tools";

const PopupLogin = ({
	popupLogin,
	appUIPopupLoginShow,
	appUIPopupLoginHide,
	appUIErrorsShow,
	authLocalLoginInit,
	authSocialLoginInit,
	s,
}) => {
	const intl = useIntl();

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const localLogin = (e) => {
		e.preventDefault();
		authLocalLoginInit(intl.locale, email, password);
	};

	const handleGoogleResponseSuccess = (response) => {
		if (popupLogin) {
			// si no hago esto, se dispara el social login ni bien se carga el <GoogleLogin> en el dom si detecta usuario de google
			const { tokenId } = response;
			authSocialLoginInit(intl.locale, {
				provider: "google",
				token: tokenId,
			});
		}
	};

	const handleGoogleResponseFailure = (response) => {
		console.log(response);
		if (popupLogin)
			// si no hago este checkeo, se dispara el error ni bien se carga el <GoogleLogin> en el dom si no detecta usuario de google
			appUIErrorsShow([s.popups?.login.googleerror]);
	};

	const startAppleLogin = async () => {
		try {
			window.AppleID.auth.init({
				clientId: "shareyourboard.app.crowdland",
				redirectURI: "https://syb.netlify.app",
				scope: "name email",
				usePopup: true,
				state: "signin", // string libre que vuelve en el post a redirectURI
			});
			const data = await window.AppleID.auth.signIn();
			console.log(data);
			authSocialLoginInit(intl.locale, {
				provider: "apple",
				token: data.authorization.code,
			});
		} catch (error) {
			// popup_closed_by_user
			// user_cancelled_authorize
			console.log(error);
		}
		/**
		 * https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/configuring_your_webpage_for_sign_in_with_apple
		 */
	};

	return (
		<article className={`popup popup-login ${popupLogin && `show`}`}>
			<span
				className="close-popup overlay show-lg"
				onClick={() => {
					appUIPopupLoginHide();
				}}
			></span>
			<div className={`wrapper login-box black-bg-01 ${!popupLogin && `hidden`}`}>
				<span className="close-popup close-button black-bg-01" onClick={appUIPopupLoginHide}></span>
				<div className="box black-bg-03">
					<h4 className="white-text prequel-font">{Tools.convertString(s.popups?.login.title)}</h4>
					<p className="white-text">{Tools.convertString(s.popups?.login?.subtitle)}</p>
					{/* <button className="button button-create black-bg-03 white-text" onClick={startAppleLogin}>
						<span className="apple-icon"></span>
						{Tools.convertString(s.popups?.login.applelogin)}
					</button> */}
					<GoogleLogin
						clientId="688149379161-on8veu2dho2g5vs1j6qv5bs0q7riokk5.apps.googleusercontent.com"
						render={(renderProps) => (
							<button
								className="button button-create black-bg-03 white-text"
								onClick={renderProps.onClick}
								disabled={renderProps.disabled}
							>
								<span className="google-icon"></span>
								{Tools.convertString(s.popups?.login.googlelogin)}
							</button>
						)}
						buttonText="Login"
						onSuccess={handleGoogleResponseSuccess}
						onFailure={handleGoogleResponseFailure}
						cookiePolicy={"single_host_origin"}
					></GoogleLogin>
					{/* )} */}
				</div>
				<p className="or align-center white-text">{Tools.convertString(s.popups?.login.o)}</p>
				<form className="black-bg-01 box" onSubmit={(e) => localLogin(e)}>
					<fieldset>
						<label>
							<input
								className="input black-bg-01 white-text"
								placeholder={Tools.convertString(s.popups?.login.email)}
								type="text"
								name="mail"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
							<span className="white-text">{Tools.convertString(s.popups?.login.email)}</span>
						</label>
						<label className="password">
							<input
								className="input black-bg-01 white-text"
								placeholder={Tools.convertString(s.popups?.login.password)}
								type="password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
							<span className="white-text">{Tools.convertString(s.popups?.login.password)}</span>
						</label>
						<small className="white-text">
							<Link to="/recuperarcontrasena" className="toggle-box white-text" onClick={appUIPopupLoginHide}>
								{Tools.convertString(s.popups?.login.recuperar)}
							</Link>
						</small>
					</fieldset>
					<button className="button login white-bg black-text-01" type="submit">
						{Tools.convertString(s.popups?.login.iniciasesion)}
					</button>
					<div className="white-text align-center">
						<p className="white-text">
							{Tools.convertString(s.popups?.login.nocuenta)}
							<Link to="/crearcuenta" className="toggle-box white-text" onClick={appUIPopupLoginHide}>
								{Tools.convertString(s.popups?.login.registrate)}
							</Link>
						</p>
					</div>
				</form>
			</div>
		</article>
	);
};

const mapStateToProps = (state) => {
	const { popupLogin } = state.app.ui;
	return {
		popupLogin,
		s: state.app.boot.strings,
	};
};

const mapDispatchToProps = {
	appUIPopupLoginShow,
	appUIPopupLoginHide,
	appUIErrorsShow,
	authLocalLoginInit,
	authSocialLoginInit,
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupLogin);
