import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

const MapCustom = ({ lat, long, s }) => {
	const mapRef = useRef(null);
	const [mapStarted, setMapStarted] = useState(false);
	//
	// useEffect(() => {
	// 	setMapStarted(false);
	// }, [lat, long]);

	useEffect(() => {
		if (window && mapRef.current && !mapStarted && lat && long) {
			setMapStarted(true);
			const { L } = window;
			const map = L.map(mapRef?.current).setView([parseFloat(lat), parseFloat(long)], 15);
			L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
				pixelRatio: 1,
				attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
				maxZoom: 18,
				tileSize: 512,
				zoomOffset: -1,
			}).addTo(map);
			L.marker([parseFloat(lat), parseFloat(long)]).addTo(map);
		}
	}, [mapRef.current, mapStarted]);

	return <div className="map" ref={mapRef} />;
};

const mapStateToProps = (state) => {
	return {
		s: state.app.boot.strings,
	};
};

export default connect(mapStateToProps)(MapCustom);
