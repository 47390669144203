import actionTypes from "../actionTypes";

export const anunciosGetPropios = (language, token = null) => {
	return {
		type: actionTypes.ANUNCIOS_PROPIOS_LOAD_INIT,
		payload: {
			language,
			token,
		},
	};
};

// export const setPais = pais => {
// 	return {
// 		type: actionTypes.ANUNCIOS_AJENOS_SET_PAIS,
// 		payload: pais,
// 	};
// };

// export const setZona = zona => {
// 	return {
// 		type: actionTypes.ANUNCIOS_AJENOS_SET_ZONA,
// 		payload: zona,
// 	};
// };

// export const setLocalizacion = localizacion => {
// 	return {
// 		type: actionTypes.ANUNCIOS_AJENOS_SET_LOCALIZACION,
// 		payload: localizacion,
// 	};
// };

// export const setTabla = tabla => {
// 	return {
// 		type: actionTypes.ANUNCIOS_AJENOS_SET_TABLA,
// 		payload: tabla,
// 	};
// };

export const anunciosSearchSet = (search) => {
	return {
		type: actionTypes.ANUNCIOS_AJENOS_SEARCH_SET,
		payload: search,
	};
};

export const anunciosGetAjenos = (language, token, params) => {
	return {
		type: actionTypes.ANUNCIOS_AJENOS_LOAD_INIT,
		payload: {
			language,
			token,
			params,
		},
	};
};

export const anunciosResetAjenos = () => {
	return {
		type: actionTypes.ANUNCIOS_AJENOS_RESET,
	};
};

export const anuncioGet = (language, id) => {
	return {
		type: actionTypes.ANUNCIO_LOAD_INIT,
		payload: {
			language,
			id,
		},
	};
};

export const anuncioReset = () => {
	return {
		type: actionTypes.ANUNCIO_RESET,
	};
};

export const anuncioSave = (language, data) => {
	return {
		type: actionTypes.ANUNCIO_SAVE_INIT,
		payload: {
			language,
			data,
		},
	};
};

export const anuncioDelete = (language, token, id) => {
	return {
		type: actionTypes.ANUNCIO_DELETE_INIT,
		payload: {
			language,
			token,
			id,
		},
	};
};

export const anuncioHotelesGet = (language, params) => {
	return {
		type: actionTypes.ANUNCIOHOTELES_LOAD_INIT,
		payload: {
			language,
			params,
		},
	};
};

export const anuncioContacto = (language, data) => {
	return {
		type: actionTypes.ANUNCIOCONTACTO_INIT,
		payload: {
			language,
			data,
		},
	};
};
