import React from "react";
import { connect } from "react-redux";
import { appUIPopupPeticionMensajeHide } from "../../redux/app/actions";

const PopupPeticionMensaje = ({ popupPeticionMensaje, appUIPopupPeticionMensajeHide, s }) => {
	return (
		<article className={`popup popup-request-msg ${popupPeticionMensaje && `show`}`}>
			<span className="close-popup overlay show-lg" onClick={appUIPopupPeticionMensajeHide}></span>
			<div className="wrapper black-bg-03">
				<span className="close-popup close-button black-bg-01" onClick={appUIPopupPeticionMensajeHide}></span>
				<div className="box">
					<h5 className="red-text prequel-font">Bravo!</h5>
					<h2 className="white-text prequel-font">Tu peticion ha sido enviada</h2>
					<p className="white-text">
						Explicación más detallada de cómo sigue el procedimiento lorem ipsum dolor sit amet, consectetur
						adipiscing elit, sed do eiusmod tempor incididunt ut labore.
					</p>
				</div>
			</div>
		</article>
	);
};

const mapStateToProps = (state, ownProps) => {
	const { popupPeticionMensaje } = state.app.ui;
	return {
		popupPeticionMensaje,
		s: state.app.boot.strings,
	};
};

const mapDispatchToProps = {
	appUIPopupPeticionMensajeHide,
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupPeticionMensaje);
