import React from "react";
import { connect } from "react-redux";
import { appUIPopupVideoHide } from "../../redux/app/actions";
import YouTube from "react-youtube";

const PopupVideo = ({ appUIPopupVideoHide, popupVideo, s }) => {
	const { show, videoId } = popupVideo;
	const onClose = () => {
		appUIPopupVideoHide();
	};

	return (
		<article className={`popup popup-video ${show && `show`}`}>
			<span className="close-popup overlay" onClick={onClose} />
			<div className="wrapper">
				<span className="close-popup close-button black-bg-01" onClick={onClose} />
				<div className="video-wrapper">
					<YouTube
						opts={{
							width: "960",
							height: "540",
							playerVars: {
								autoplay: 1,
							},
						}}
						videoId={videoId}
					/>
				</div>
			</div>
		</article>
	);
};

const mapStateToProps = (state) => {
	const { popupVideo } = state.app.ui;
	return {
		popupVideo,
		s: state.app.boot.strings,
	};
};

const mapDispatchToProps = {
	appUIPopupVideoHide,
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupVideo);
