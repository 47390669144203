import actionTypes from "../actionTypes";

export const appNetworkErrorReset = () => {
	return {
		type: actionTypes.APP_NETWORK_ERROR_RESET,
	};
};

export const appBootGet = (language) => {
	return {
		type: actionTypes.APP_BOOT_INIT,
		payload: language,
	};
};

export const appUISidenavToggle = () => {
	return {
		type: actionTypes.APP_UI_SIDENAV_TOGGLE,
	};
};

export const appUIDropdownMenuToggle = () => {
	return {
		type: actionTypes.APP_UI_DROPDOWNMENU_TOGGLE,
	};
};

export const appUIPopupLoginShow = () => {
	return {
		type: actionTypes.APP_UI_POPUP_LOGIN_SHOW,
	};
};

export const appUIPopupLoginHide = () => {
	return {
		type: actionTypes.APP_UI_POPUP_LOGIN_HIDE,
	};
};

export const appUIPopupEliminarAnuncioShow = (id, title) => {
	return {
		type: actionTypes.APP_UI_POPUP_ELIMINAR_ANUNCIO_SHOW,
		payload: {
			id,
			title,
		},
	};
};

export const appUIPopupEliminarAnuncioHide = () => {
	return {
		type: actionTypes.APP_UI_POPUP_ELIMINAR_ANUNCIO_HIDE,
	};
};

export const appUIPopupTooltipShow = () => {
	return {
		type: actionTypes.APP_UI_POPUP_TOOLTIP_SHOW,
	};
};

export const appUIPopupTooltipHide = () => {
	return {
		type: actionTypes.APP_UI_POPUP_TOOLTIP_HIDE,
	};
};

export const appUIPopupFiltroZonaShow = () => {
	return {
		type: actionTypes.APP_UI_POPUP_FILTROZONA_SHOW,
	};
};

export const appUIPopupFiltroZonaHide = () => {
	return {
		type: actionTypes.APP_UI_POPUP_FILTROZONA_HIDE,
	};
};

export const appUIPopupMasFiltrosShow = () => {
	return {
		type: actionTypes.APP_UI_POPUP_MASFILTROS_SHOW,
	};
};

export const appUIPopupMasFiltrosHide = () => {
	return {
		type: actionTypes.APP_UI_POPUP_MASFILTROS_HIDE,
	};
};

export const appUIPopupOrdenarShow = () => {
	return {
		type: actionTypes.APP_UI_POPUP_ORDENAR_SHOW,
	};
};

export const appUIPopupOrdenarHide = () => {
	return {
		type: actionTypes.APP_UI_POPUP_ORDENAR_HIDE,
	};
};

export const appUIPopupEligeComunidadesShow = () => {
	return {
		type: actionTypes.APP_UI_POPUP_ELIGECOMUNIDADES_SHOW,
	};
};

export const appUIPopupEligeComunidadesHide = () => {
	return {
		type: actionTypes.APP_UI_POPUP_ELIGECOMUNIDADES_HIDE,
	};
};

export const appUIPopupPeticionMensajeShow = () => {
	return {
		type: actionTypes.APP_UI_POPUP_PETICION_MENSAJE_SHOW,
	};
};

export const appUIPopupPeticionMensajeHide = () => {
	return {
		type: actionTypes.APP_UI_POPUP_PETICION_MENSAJE_HIDE,
	};
};

export const appUIPopupHomeShow = () => {
	return {
		type: actionTypes.APP_UI_POPUP_HOME_SHOW,
	};
};

export const appUIPopupHomeHide = () => {
	return {
		type: actionTypes.APP_UI_POPUP_HOME_HIDE,
	};
};

export const appUIPopupTextoShow = (data) => {
	return {
		type: actionTypes.APP_UI_POPUP_TEXTO_SHOW,
		payload: data,
	};
};

export const appUIPopupTextoHide = () => {
	return {
		type: actionTypes.APP_UI_POPUP_TEXTO_HIDE,
	};
};

export const appUIPopupTypeformShow = (internalName, hiddenFields = {}) => {
	return {
		type: actionTypes.APP_UI_POPUP_TYPEFORM_SHOW,
		payload: {
			internalName,
			hiddenFields,
		},
	};
};

export const appUIPopupTypeformHide = () => {
	return {
		type: actionTypes.APP_UI_POPUP_TYPEFORM_HIDE,
	};
};

export const appUIPopupMapShow = (location) => {
	return {
		type: actionTypes.APP_UI_POPUP_MAP_SHOW,
		payload: location,
	};
};

export const appUIPopupMapHide = () => {
	return {
		type: actionTypes.APP_UI_POPUP_MAP_HIDE,
	};
};

export const appUIPopupVideoShow = (videoId) => {
	return {
		type: actionTypes.APP_UI_POPUP_VIDEO_SHOW,
		payload: videoId,
	};
};

export const appUIPopupVideoHide = () => {
	return {
		type: actionTypes.APP_UI_POPUP_VIDEO_HIDE,
	};
};

export const appSetIsMobile = (isMobile) => {
	return {
		type: actionTypes.APP_SET_IS_MOBILE,
		payload: isMobile,
	};
};

export const appUIErrorsShow = (errors) => {
	return {
		type: actionTypes.APP_UI_ERRORS_SHOW,
		payload: errors,
	};
};

export const appUIErrorsHide = () => {
	return {
		type: actionTypes.APP_UI_ERRORS_HIDE,
	};
};

export const appUISuccessHide = () => {
	return {
		type: actionTypes.APP_UI_SUCCESS_HIDE,
	};
};

export const appUIRegisterPageBack = () => {
	return {
		type: actionTypes.AUTH_LOCAL_REGISTER_PAGE_BACK,
	};
};

export const appUICrearAnuncioPageBack = () => {
	return {
		type: actionTypes.CREAR_ANUNCIO_PAGE_BACK,
	};
};

export const appRedirect = ({ to, options = {} }) => {
	return {
		type: actionTypes.APP_REDIRECT,
		payload: {
			to,
			options,
		},
	};
};

export const appRedirectReset = () => {
	return {
		type: actionTypes.APP_REDIRECT_RESET,
	};
};

export const appGeoSet = (lat, long, descripcion) => {
	return {
		type: actionTypes.APP_GEO_SET,
		payload: {
			lat,
			long,
			descripcion,
		},
	};
};

export const appAreaSet = (area) => {
	return {
		type: actionTypes.APP_AREA_SET,
		payload: area,
	};
};

export const anunciosHomeInit = () => {
	return {
		type: actionTypes.APP_ANUNCIOS_HOME_INIT,
	};
};

export const anunciosHomeGet = (language, params) => {
	return {
		type: actionTypes.APP_ANUNCIOS_HOME_LOAD_INIT,
		payload: {
			language,
			params,
		},
	};
};
