import React from "react";
import { connect } from "react-redux";
import { appUIPopupEliminarAnuncioHide } from "../../redux/app/actions";
import { anuncioDelete } from "../../redux/anuncios/actions";
import { useIntl } from "gatsby-plugin-intl";
import Tools from "../../classes/tools";

const PopupEliminarAnuncio = ({
	popupEliminarAnuncio,
	popupEliminarAnuncioId,
	popupEliminarAnuncioTitle,
	appUIPopupEliminarAnuncioHide,
	token,
	anuncioDelete,
	s,
}) => {
	const intl = useIntl();

	const doDelete = () => {
		anuncioDelete(intl.locale, token, popupEliminarAnuncioId);
	};

	return (
		<article className={`popup popup-post-delete ${popupEliminarAnuncio && `show`}`}>
			<span className="close-popup overlay show-lg" onClick={appUIPopupEliminarAnuncioHide}></span>
			<div className="wrapper black-bg-03">
				<span className="close-popup close-button black-bg-01" onClick={appUIPopupEliminarAnuncioHide}></span>
				<div className="box">
					<h2 className="white-text prequel-font">{Tools.convertString(s.popups?.eliminaranuncio.title)}</h2>
					<p className="white-text">
						{Tools.convertString(s.popups?.eliminaranuncio.text)} “
						<span className="title">{popupEliminarAnuncioTitle}</span>”?
					</p>
					<button className="button white-bg black-text-01" onClick={doDelete}>
						{Tools.convertString(s.popups?.eliminaranuncio.button)}
					</button>
				</div>
			</div>
		</article>
	);
};

const mapStateToProps = (state, ownProps) => {
	const { popupEliminarAnuncio, popupEliminarAnuncioId, popupEliminarAnuncioTitle } = state.app.ui;
	const { token } = state.auth.usuario;
	return {
		popupEliminarAnuncio,
		popupEliminarAnuncioId,
		popupEliminarAnuncioTitle,
		token,
		s: state.app.boot.strings,
	};
};

const mapDispatchToProps = {
	appUIPopupEliminarAnuncioHide,
	anuncioDelete,
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupEliminarAnuncio);
