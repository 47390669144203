import React from "react";
import { connect } from "react-redux";
import { appUIPopupMapHide } from "../../redux/app/actions";
import { useIntl } from "gatsby-plugin-intl";
import MapCustom from "../common/MapCustom";
import Tools from "../../classes/tools";

const PopupMap = ({ popupMap, appUIPopupMapHide, s }) => {
	const intl = useIntl();
	return (
		<article className={`popup popup-map ${popupMap.show && `show`}`}>
			<span className="close-popup overlay show-lg" onClick={appUIPopupMapHide} />
			<div className="wrapper black-bg-03">
				<span className="close-popup close-button black-bg-01" onClick={appUIPopupMapHide} />
				<div className="box">
					<h4 className="white-text prequel-font">{Tools.convertString(s.popups?.map.title)}</h4>
					<p className="white-text">{Tools.convertString(s.popups?.map.text)}</p>

					<div className="map-wrapper">
						<MapCustom lat={popupMap.location?.lat} long={popupMap.location?.long} />
					</div>
				</div>
			</div>
		</article>
	);
};

const mapStateToProps = (state) => {
	const { popupMap } = state.app.ui;
	return {
		popupMap,
		s: state.app.boot.strings,
	};
};

const mapDispatchToProps = {
	appUIPopupMapHide,
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupMap);
