import React, { useEffect } from "react";
import "semantic-ui-css/semantic.min.css";
import "../../assets/css/styles.scss";
import Header from "../header";
import PopupLogin from "../popupLogin";
import PopupEmailVerification from "../popupEmailVerification";
import PopupEliminarAnuncio from "../popupEliminarAnuncio";
import PopupTooltip from "../popupTooltip";
import PopupEligeComunidades from "../popupEligeComunidades";
import PopupPeticionMensaje from "../popupPeticionMensaje";
import { Dimmer, Button, Modal } from "semantic-ui-react";
import { BlockLoading } from "react-loadingg";
import { connect } from "react-redux";
import {
	appBootGet,
	appUIErrorsHide,
	appUISuccessHide,
	appUIPopupTextoHide,
	appNetworkErrorReset,
	appRedirectReset,
	appSetIsMobile,
} from "../../redux/app/actions";
import { navigate, useIntl } from "gatsby-plugin-intl";
import { useStaticQuery, graphql } from "gatsby";
import CookieConsent from "react-cookie-consent";
import PopupTypeform from "../PopupTypeform";
import PopupMap from "../PopupMap";
import PopupVideo from "../PopupVideo";
import useResizeAware from "react-resize-aware";
import { sizesValues } from "../../assets/styles/size";
import Tools from "../../classes/tools";
import Header2 from "../Header2";

const Layout = ({
	children,
	appNetworkLoading,
	appNetworkError,
	appBootGet,
	appBootLoaded,
	appUIErrors,
	appUIErrorsHide,
	appUIErrorsList,
	appUISuccess,
	appUISuccessTitle,
	appUISuccessMessage,
	appUISuccessHide,
	appNetworkErrorReset,
	appRedirectTo,
	appRedirectOptions,
	appRedirectReset,
	appUIPopupTexto,
	appUIPopupTextoTitle,
	appUIPopupTextoBody,
	appUIPopupTextoButton,
	appUIPopupTextoHide,
	onHeaderGeoLocationChange,
	appIsMobile,
	appSetIsMobile,
	s,
}) => {
	const { fase } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						fase
					}
				}
			}
		`
	).site.siteMetadata;

	const intl = useIntl();
	const [resizeListener, sizes] = useResizeAware();

	useEffect(() => {
		// https://github.com/zsajjad/react-facebook-pixel/issues/65
		// hay que hacer este asunto, sino falla porque busca window al buildear
		import("react-facebook-pixel")
			.then((module) => module.default)
			.then((ReactPixel) => {
				ReactPixel.init("988219825211549");
				ReactPixel.pageView();
			});
	}, []);

	useEffect(() => {
		if (typeof window !== undefined) {
			try {
				// window.gtag("event", "conversion", {
				// 	send_to: "AW-609429651/lImoCN_whuECEJPRzKIC",
				// });
			} catch (ex) {}
		}
	}, []);

	useEffect(() => {
		if (typeof window !== undefined) {
			window.addEventListener("resize", () => {
				const vh = window.innerHeight * 0.01;
				document.documentElement.style.setProperty("--vh", `${vh}px`);
			});
		}
		if (!appBootLoaded) appBootGet(intl.locale);
	}, []);

	useEffect(() => {
		if (appRedirectTo !== null) {
			navigate(appRedirectTo, appRedirectOptions);
			appRedirectReset();
		}
	}, [appRedirectTo, appRedirectOptions, appRedirectReset]);

	const reintentar = () => {
		appNetworkErrorReset();
		window.location = "/";
	};

	useEffect(() => {
		if (sizes.width < sizesValues.lg0) {
			!appIsMobile && appSetIsMobile(true);
		} else {
			appIsMobile && appSetIsMobile(false);
		}
	}, [sizes.width]);

	return (
		<>
			{resizeListener}

			{appBootLoaded && (
				<>
					{/* Header */}
					{/*<Header onGeoLocationChange={onHeaderGeoLocationChange} />*/}
					<Header2 onGeoLocationChange={onHeaderGeoLocationChange} />

					{/* Popups */}
					<PopupLogin />
					<PopupEmailVerification />
					<PopupEliminarAnuncio />
					<PopupTooltip />
					<PopupEligeComunidades />
					<PopupPeticionMensaje />
					<PopupTypeform />
					<PopupMap />
					<PopupVideo />

					{/* Popup Error */}

					<article className={`popup popup-msg ${appUIErrors && `show`}`}>
						<span className="close-popup overlay show-lg"></span>
						<div className="wrapper black-bg-03">
							<span className="close-popup close-button black-bg-01" onClick={appUIErrorsHide}></span>
							<div className="box white-text">
								<h4 className="prequel-font red-text">{Tools.convertString(s.layout?.errores_title)}</h4>
								<ul>
									{appUIErrorsList.map((error, index) => {
										return <li key={index}>&bull; {error}</li>;
									})}
								</ul>
								<button className="button white-bg black-text-01" type="button" onClick={appUIErrorsHide}>
									{Tools.convertString(s.layout?.errores_button)}
								</button>
							</div>
						</div>
					</article>

					{/* Popup Success */}

					<article className={`popup popup-msg ${appUISuccess && `show`}`}>
						<span className="close-popup overlay show-lg"></span>
						<div className="wrapper black-bg-03">
							<span className="close-popup close-button black-bg-01" onClick={appUISuccessHide}></span>
							<div className="box white-text">
								<h4 className="prequel-font">{appUISuccessTitle}</h4>
								<p>{appUISuccessMessage}</p>
								<button className="button white-bg black-text-01" type="button" onClick={appUISuccessHide}>
									{Tools.convertString(s.layout?.success_button)}
								</button>
							</div>
						</div>
					</article>

					{/* Cookie consent */}

					<CookieConsent
						buttonText={Tools.convertString(s.cookies?.button)}
						containerClasses="cookies-container"
						contentClasses="cookies-content"
						buttonClasses="cookies-button"
						disableStyles={true}
						disableButtonStyles={true}
					>
						{Tools.convertString(s.cookies?.text)}
					</CookieConsent>

					{/* Popup texto */}

					<article className={`popup popup-tyc ${appUIPopupTexto && `show`}`}>
						<span className="close-popup overlay show-lg"></span>
						<div className="wrapper black-bg-03">
							<span className="close-popup close-button black-bg-01" onClick={appUIPopupTextoHide}></span>
							<div className="box white-text">
								<h4 className="saint-font">{appUIPopupTextoTitle}</h4>
								<p>
									<div dangerouslySetInnerHTML={{ __html: appUIPopupTextoBody }} />
								</p>
								<button className="button white-bg black-text-01" type="button" onClick={appUIPopupTextoHide}>
									{appUIPopupTextoButton}
								</button>
							</div>
						</div>
					</article>

					{/* Hey, teacher, leave those kids alone! */}

					{children}
				</>
			)}

			{/* Avisos de loading y network error */}

			<Dimmer page active={appNetworkLoading} style={{ zIndex: 10000 }}>
				<BlockLoading color="#FF0000" />
			</Dimmer>

			<Dimmer page active={appNetworkError} style={{ zIndex: 10000 }}>
				<p>{intl.formatMessage({ id: "layout.network_error" })}</p>
				<Button basic color="green" inverted onClick={reintentar}>
					{intl.formatMessage({ id: "layout.network_retry" })}
				</Button>
			</Dimmer>
		</>
	);
};

const mapStateToProps = (state) => {
	const { loading: appNetworkLoading, error: appNetworkError } = state.app.network;
	const { loaded: appBootLoaded } = state.app.boot;
	const {
		errors: appUIErrors,
		errorsList: appUIErrorsList,
		success: appUISuccess,
		successTitle: appUISuccessTitle,
		successMessage: appUISuccessMessage,
		popupTexto: appUIPopupTexto,
		popupTextoTitle: appUIPopupTextoTitle,
		popupTextoBody: appUIPopupTextoBody,
		popupTextoButton: appUIPopupTextoButton,
	} = state.app.ui;
	const { redirectTo: appRedirectTo, redirectOptions: appRedirectOptions, isMobile: appIsMobile } = state.app;
	return {
		appNetworkLoading,
		appNetworkError,
		appBootLoaded,
		appUIErrors,
		appUIErrorsList,
		appUISuccess,
		appUISuccessTitle,
		appUISuccessMessage,
		appRedirectTo,
		appRedirectOptions,
		appIsMobile,
		appUIPopupTexto,
		appUIPopupTextoTitle,
		appUIPopupTextoBody,
		appUIPopupTextoButton,
		s: state.app.boot.strings,
	};
};

const mapDispatchToProps = {
	appSetIsMobile,
	appBootGet,
	appUIErrorsHide,
	appUISuccessHide,
	appUIPopupTextoHide,
	appNetworkErrorReset,
	appRedirectReset,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
