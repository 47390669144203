export const sizesValues = {
	xs: 319,
	xs2: 414,
	sm: 576,
	md: 768,
	lg0: 991,
	lg: 992,
	lg2: 1024,
	lg3: 1150,
	xl: 1280,
	xl2: 1366,
	xl3: 1440,
	xxl: 1500,
};

export const sizes = {
	xs: "319px",
	xs2: "414px",
	sm: "576px",
	md: "768px",
	lg0: "991px",
	lg: "992px",
	lg2: "1024px",
	lg3: "1150px",
	xl: "1280px",
	xl2: "1366px",
	xl3: "1440px",
	xxl: "1500px",
};

export const mediaQueryMin = {
	xs: `(min-width: ${sizes.xs})`,
	xs2: `(min-width: ${sizes.xs2})`,
	sm: `(min-width: ${sizes.sm})`,
	md: `(min-width: ${sizes.md})`,
	lg0: `(min-width: ${sizes.lg0})`,
	lg: `(min-width: ${sizes.lg})`,
	lg2: `(min-width: ${sizes.lg2})`,
	lg3: `(min-width: ${sizes.lg3})`,
	xl: `(min-width: ${sizes.xl})`,
	xl2: `(min-width: ${sizes.xl2})`,
	xl3: `(min-width: ${sizes.xl3})`,
	xxl: `(min-width: ${sizes.xxl})`,
};

export const mediaQueryMax = {
	xs: `(max-width: ${sizes.xs})`,
	xs2: `(max-width: ${sizes.xs2})`,
	sm: `(max-width: ${sizes.sm})`,
	md: `(max-width: ${sizes.md})`,
	lg0: `(max-width: ${sizes.lg0})`,
	lg: `(max-width: ${sizes.lg})`,
	lg2: `(max-width: ${sizes.lg2})`,
	lg3: `(max-width: ${sizes.lg3})`,
	xl: `(max-width: ${sizes.xl})`,
	xl2: `(max-width: ${sizes.xl2})`,
	xl3: `(max-width: ${sizes.xl3})`,
	xxl: `(max-width: ${sizes.xxl})`,
};

// Vars Height --------------------------- */

export const heights = {
	headerHeight: "75px",
	headerLgHeight: "100px",
	homeBoxHeight: "255px",
};
