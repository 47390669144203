import React from "react";
import { connect } from "react-redux";
import { appUIPopupTooltipHide } from "../../redux/app/actions";
import { useIntl } from "gatsby-plugin-intl";
import Tools from "../../classes/tools";

const PopupTooltip = ({ popupTooltip, appUIPopupTooltipHide, s }) => {
	const intl = useIntl();
	return (
		<article className={`popup popup-tooltip ${popupTooltip && `show`}`}>
			<span className="close-popup overlay show-lg" onClick={appUIPopupTooltipHide}></span>
			<div className="wrapper black-bg-03">
				<span className="close-popup close-button black-bg-01" onClick={appUIPopupTooltipHide}></span>
				<div className="box">
					<h4 className="white-text prequel-font">{Tools.convertString(s.popups?.tooltip.title)}</h4>
					<p className="white-text">{Tools.convertString(s.popups?.tooltip.text)}</p>
				</div>
			</div>
		</article>
	);
};

const mapStateToProps = (state, ownProps) => {
	const { popupTooltip } = state.app.ui;
	return {
		popupTooltip,
		s: state.app.boot.strings,
	};
};

const mapDispatchToProps = {
	appUIPopupTooltipHide,
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupTooltip);
