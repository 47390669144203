import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { appUIPopupTypeformHide } from "../../redux/app/actions";
import { useIntl } from "gatsby-plugin-intl";
import { Widget } from "@typeform/embed-react";

const PopupTypeform = ({ appUIPopupTypeformHide, popupTypeform, s }) => {
	const { show, typeformData } = popupTypeform;

	console.log("typeformData", typeformData);

	const [formId, setFormId] = useState(null);

	useEffect(() => {
		if (typeformData) {
			switch (typeformData.internalName) {
				case "comprar":
					setFormId("");
					break;
				case "alquilar":
					setFormId("XEOhSUz7");
					break;
				case "consultar":
					setFormId("");
					break;
				case "denunciar":
					setFormId("iEE52gm6");
					break;
				case "asesoramiento":
					setFormId("D1CVzZCY");
					break;
				default:
					setFormId(null);
					break;
			}
		}
	}, [typeformData]);

	const onClose = () => {
		appUIPopupTypeformHide();
		setTimeout(() => {
			setFormId(null);
		}, 350);
	};

	return typeformData ? (
		<>
			<article className={`popup popup-typeform ${show && `show`}`}>
				<span className="close-popup overlay show-lg" onClick={onClose}></span>
				<div className="wrapper black-bg-01">
					<span className="close-popup close-button black-bg-01" onClick={onClose}></span>
					<div className="box">
						<div className="typeform-wrapper">
							{formId !== null && <Widget id={formId} className="my-form" hidden={typeformData.hiddenFields} />}
						</div>
					</div>
				</div>
			</article>
		</>
	) : null;
};

const mapStateToProps = (state) => {
	const { popupTypeform } = state.app.ui;
	return {
		popupTypeform,
		s: state.app.boot.strings,
	};
};

const mapDispatchToProps = {
	appUIPopupTypeformHide,
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupTypeform);
