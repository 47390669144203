import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { appUIPopupEligeComunidadesHide } from "../../redux/app/actions";
import { useStaticQuery, graphql } from "gatsby";
import { useIntl, Link } from "gatsby-plugin-intl";
import { createPathPairs } from "../../hocs/withPathPairs";
import { newsletterSuscripcionAdd } from "../../redux/newsletter/actions";
import Tools from "../../classes/tools";

const PopupEligeComunidades = ({
	popupEligeComunidades,
	appUIPopupEligeComunidadesHide,
	newsletterSuscripcionAdd,
	newsletterSuscripcionAddDone,
	s,
}) => {
	const intl = useIntl();

	const [newsletterEmail, setNewsletterEmail] = useState("");

	useEffect(() => {
		if (newsletterSuscripcionAddDone) setNewsletterEmail("");
	}, [newsletterSuscripcionAddDone]);

	const handleNewsletterSuscripcionAdd = (e) => {
		e.preventDefault();
		newsletterSuscripcionAdd(intl.locale, {
			email: newsletterEmail,
		});
	};

	const query = useStaticQuery(
		graphql`
			{
				allInternalPaises(
					filter: {
						alternative_id: { ne: null }
						zonas: {
							elemMatch: {
								alternative_id: { ne: null }
								localizaciones: { elemMatch: { alternative_id: { ne: null } } }
							}
						}
					}
				) {
					nodes {
						id: alternative_id
						title
						intl {
							title {
								es
								en
							}
						}
						zonas {
							id: alternative_id
							title
							descripcion
							imagen {
								url
							}
							destacado
							intl {
								title {
									es
									en
								}
								descripcion {
									es
									en
								}
							}
							localizaciones {
								id: alternative_id
								title
								intl {
									title {
										es
										en
									}
								}
							}
						}
					}
				}
			}
		`
	);
	const { nodes: paises } = query.allInternalPaises;
	return (
		<article className={`popup popup-eligecomunidades ${popupEligeComunidades && `show`}`}>
			<span className="close-popup overlay show-lg" onClick={appUIPopupEligeComunidadesHide}></span>
			<div className="wrapper black-bg-03">
				<span className="close-popup close-button black-bg-01" onClick={appUIPopupEligeComunidadesHide}></span>
				<div className="box">
					<h4 className="white-text prequel-font">Elige entre estas comunidades</h4>
					<ul className="locations">
						{paises.map((pais) => {
							return pais.zonas.map((zona) => {
								return (
									zona.destacado && (
										<li key={zona.id}>
											<Link
												to={`/anuncios/${createPathPairs({ p: pais.id, z: zona.id })}`}
												onClick={appUIPopupEligeComunidadesHide}
											>
												{zona.imagen && zona.imagen.url && <img src={zona.imagen.url} />}
												<span className="wrapper-text black-text-01 white-bg">
													<p className="title">{zona.intl.title[intl.locale]}</p>
													<small className="description">{zona.intl.descripcion[intl.locale]}</small>
												</span>
											</Link>
										</li>
									)
								);
							});
						})}
					</ul>
				</div>
				<div className="blue-bg no-zone">
					<div className="box blue-bg white-text wrapper-text">
						<h4 className="prequel-font">¿No encuentras tu zona?</h4>
						<p>
							Activamos sólo las zonas en las que tenemos un número mínimo de tablas. ¡Suscríbete a nuestra
							newsletter y cuando abramos una zona nueva serás el primero en enterarte!
						</p>
						<form onSubmit={handleNewsletterSuscripcionAdd}>
							<input
								className="input black-text-03"
								placeholder={Tools.convertString(s.footer?.email)}
								name="newsletter"
								id="newsletter"
								type="text"
								value={newsletterEmail}
								onChange={(e) => setNewsletterEmail(e.target.value)}
							/>
							<button type="submit" className="button black-bg-01 white-text">
								{Tools.convertString(s.footer?.enviar)}
							</button>
						</form>
					</div>
				</div>
			</div>
		</article>
	);
};

const mapStateToProps = (state, ownProps) => {
	const { popupEligeComunidades } = state.app.ui;
	const { newsletterSuscripcionAddDone } = state.app.events;
	return {
		popupEligeComunidades,
		newsletterSuscripcionAddDone,
		s: state.app.boot.strings,
	};
};

const mapDispatchToProps = {
	appUIPopupEligeComunidadesHide,
	newsletterSuscripcionAdd,
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupEligeComunidades);
