import React, { useState, useEffect, useRef } from "react";
import logo from "../../assets/gfx/logo.svg";
import { ReactSVG } from "react-svg";
import { useIntl, changeLocale, Link, navigate } from "gatsby-plugin-intl";
import { connect } from "react-redux";
import {
	appUISidenavToggle,
	appUIDropdownMenuToggle,
	appUIPopupLoginShow,
	appRedirect,
	appBootGet,
	appUIErrorsShow,
} from "../../redux/app/actions";
import { authLogout } from "../../redux/auth/actions";
import Geosuggest from "react-geosuggest";
import langES from "../../assets/gfx/lang-es.svg";
import langEN from "../../assets/gfx/lang-en.png";
import langFR from "../../assets/gfx/lang-fr.svg";
import langPT from "../../assets/gfx/lang-pt.svg";
import Tools from "../../classes/tools";
import {
	StyledHeader,
	InnerContent,
	Top,
	Bottom,
	Logo,
	Search,
	LoginBtn,
	MobileMenuBtn,
	LangButton,
	DropdownMenu,
	MobileMenu,
} from "./styled";

const Header = ({
	sideNav,
	appUISidenavToggle,
	appUIDropdownMenuToggle,
	dropdownMenu,
	appUIPopupLoginShow,
	loggedIn,
	authResolved,
	authLogout,
	appRedirect,
	usuario,
	appBootGet,
	geo,
	appIsMobile,
	onGeoLocationChange,
	appUIErrorsShow,
	s,
}) => {
	const intl = useIntl();

	const [headerScrolledClass, setHeaderScrolledClass] = useState("");

	useEffect(() => {
		window.addEventListener("scroll", () => {
			if (window.scrollY >= 300) {
				setHeaderScrolledClass("scrolled");
			} else {
				setHeaderScrolledClass("");
			}
		});
	}, []);

	const crearAnuncio = () => {
		if (loggedIn) {
			if (sideNav) appUISidenavToggle();
			appRedirect({ to: usuario.completo ? `/crearanuncio` : `/editarcuenta` });
		} else appUIPopupLoginShow();
	};

	const setLang = (language) => {
		appBootGet(language);
		changeLocale(language);
	};

	const geoSuggestRef = useRef(null);

	const onGeoSuggestSelect = (suggest) => {
		if (suggest) {
			sideNav && appUISidenavToggle();
			const { lat, lng } = suggest.location;
			const { description } = suggest;
			onGeoLocationChange({ lat, long: lng, descripcion: description });
		}
	};

	const goAnuncios = () => {
		if (!geo.lat || !geo.long || !geo.descripcion) {
			appUIErrorsShow(["Elige una ubicación para encontrar tu tabla."]);
		} else {
			sideNav && appUISidenavToggle();
			navigate(
				`/anuncios/mode/geo/lat/${geo.lat}/long/${geo.long}/geo_descripcion/${geo.descripcion}/orden/distancia`
			);
		}
	};

	const renderMenuLinks = () => {
		return (
			<ul>
				<li>
					<Link to="/filosofia" className="black-text-01" onClick={sideNav ? appUISidenavToggle : undefined}>
						{Tools.convertString(s.header?.filosofia)}
					</Link>
				</li>
				<li>
					<Link to="/porquealquilar" className="black-text-01" onClick={sideNav && appUISidenavToggle}>
						{Tools.convertString(s.header?.porquealquilar)}
					</Link>
				</li>
				<li>
					<Link to="/porquecompartir" className="black-text-01" onClick={sideNav && appUISidenavToggle}>
						{Tools.convertString(s.header?.porquecompartir)}
					</Link>
				</li>
			</ul>
		);
	};

	const renderMenu = () => {
		if (appIsMobile) {
			return (
				<nav>
					<ul>
						<DropdownMenu opened={dropdownMenu}>
							<span className="dropdown-button white-text hide-lg" onClick={appUIDropdownMenuToggle}>
								{Tools.convertString(s.header?.about)}
							</span>
							{renderMenuLinks()}
						</DropdownMenu>
					</ul>
				</nav>
			);
		} else {
			return <nav>{renderMenuLinks()}</nav>;
		}
	};

	const renderMenuLang = () => {
		return (
			<nav>
				<ul>
					{/* logout aparece logueado */}
					{loggedIn && authResolved && (
						<li className="logout show-lg">
							<a
								onClick={() => {
									authLogout(usuario);
									if (sideNav) appUISidenavToggle();
								}}
							>
								{Tools.convertString(s.header?.logout)}
							</a>
						</li>
					)}
					{/* Mi cuenta aparece logueado */}
					{loggedIn && (
						<li className="account-link">
							<Link to="/micuenta" onClick={sideNav ? appUISidenavToggle : undefined}>
								{Tools.convertString(s.header?.micuenta)}
							</Link>
						</li>
					)}
					{!loggedIn && authResolved && (
						<li className="login show-lg">
							<a onClick={appUIPopupLoginShow}>{Tools.convertString(s.header?.login)}</a>
						</li>
					)}
					<li>
						<Link to="/contacto" onClick={sideNav ? appUISidenavToggle : undefined}>
							{Tools.convertString(s.header?.contacto)}
						</Link>
					</li>
					<li>
						<Link to="/faqs" onClick={sideNav && appUISidenavToggle}>
							{Tools.convertString(s.header?.faqs)}
						</Link>
					</li>
					<li>
						<LangButton onClick={() => setLang("es")}>
							<ReactSVG src={langES} />
						</LangButton>
						<LangButton onClick={() => setLang("en")}>
							<img src={langEN} />
						</LangButton>
						<LangButton onClick={() => setLang("pt")}>
							<ReactSVG src={langPT} />
						</LangButton>
						{/* <LangButton onClick={() => setLang("fr")}>
							<ReactSVG src={langFR} />
						</LangButton> */}
					</li>
				</ul>
			</nav>
		);
	};

	const renderSearch = () => {
		return (
			<form
				onSubmit={(e) => {
					e.preventDefault();
				}}
			>
				<div className="formWrapper">
					<ReactSVG src="/img/location.svg" />
					<Geosuggest
						ref={geoSuggestRef}
						placeholder={Tools.convertString(s.home?.searchlocation_placeholder)}
						initialValue={geo.descripcion}
						onSuggestSelect={onGeoSuggestSelect}
						autoActivateFirstSuggest={true}
						autoCapitalize="true"
						autoComplete="new-password"
						autoCorrect="false"
						autoSave="false"
					/>
				</div>
				<button type="button" className="button redButton" onClick={goAnuncios}>
					{Tools.convertString(s.home?.searchlocation_button)}
				</button>
			</form>
		);
	};

	const renderLoginBtn = () => {
		return (
			<LoginBtn>
				{authResolved && loggedIn && (
					<Link
						to={usuario.completo ? "/crearanuncio" : "/editarcuenta"}
						className="white-text alquila_compra"
						onClick={sideNav ? appUISidenavToggle : undefined}
					>
						{Tools.convertString(s.header?.comparte)}
					</Link>
				)}
				{authResolved && !loggedIn && (
					<a className="white-text" onClick={crearAnuncio}>
						{Tools.convertString(s.header?.comparte)}
					</a>
				)}
			</LoginBtn>
		);
	};

	return (
		<StyledHeader scrolled={headerScrolledClass}>
			{!appIsMobile && (
				<Top>
					<InnerContent>
						{renderMenu()}
						{renderMenuLang()}
					</InnerContent>
				</Top>
			)}

			<Bottom scrolled={headerScrolledClass} opened={sideNav}>
				<InnerContent>
					<Logo scrolled={headerScrolledClass} opened={sideNav}>
						<Link to="/" onClick={sideNav ? appUISidenavToggle : undefined}>
							<img className="normal" src={logo} alt={Tools.convertString(s.header?.home)} />
							<img className="mini" src="/img/mini-logo.svg" alt={Tools.convertString(s.header?.home)} />
						</Link>
					</Logo>

					{!appIsMobile && <Search scrolled={headerScrolledClass}>{renderSearch()}</Search>}

					{!appIsMobile && renderLoginBtn()}

					{appIsMobile && (
						<MobileMenuBtn
							opened={sideNav}
							scrolled={headerScrolledClass}
							onClick={appUISidenavToggle}
							onKeyDown={appUISidenavToggle}
							role="button"
							tabIndex="0"
						>
							<span className="line-01" />
							<span className="line-02" />
						</MobileMenuBtn>
					)}
				</InnerContent>
			</Bottom>

			{appIsMobile && (
				<MobileMenu opened={sideNav} scrolled={headerScrolledClass}>
					<Search scrolled={headerScrolledClass}>{renderSearch()}</Search>

					<div className="menuMobileInner">
						{renderMenu()}
						{renderMenuLang()}
						{renderLoginBtn()}
						<nav>
							<ul>
								{loggedIn && authResolved && (
									<li className="logout">
										<a
											onClick={() => {
												authLogout(usuario);
												if (sideNav) appUISidenavToggle();
											}}
										>
											{Tools.convertString(s.header?.logout)}
										</a>
									</li>
								)}
								{!loggedIn && authResolved && (
									<li className="login">
										<a onClick={appUIPopupLoginShow}>{Tools.convertString(s.header?.login)}</a>
									</li>
								)}
								<li className="ig">
									<a
										href="https://www.instagram.com/shareyourboard/"
										target="_blank"
										className="white-text"
										rel="noopener noreferrer"
										onClick={sideNav ? appUISidenavToggle : undefined}
									>
										{Tools.convertString(s.header?.instagram)}
									</a>
								</li>
							</ul>
						</nav>
					</div>
				</MobileMenu>
			)}
		</StyledHeader>
	);
};

const mapStateToProps = (state) => {
	const { sideNav } = state.app.ui;
	const { dropdownMenu } = state.app.ui;
	const { loggedIn, resolved: authResolved, usuario } = state.auth;
	const { geo, isMobile: appIsMobile } = state.app;
	return {
		sideNav,
		dropdownMenu,
		loggedIn,
		authResolved,
		usuario,
		geo,
		appIsMobile,
		s: state.app.boot.strings,
	};
};

const mapDispatchToProps = {
	appUISidenavToggle,
	appUIDropdownMenuToggle,
	appUIPopupLoginShow,
	authLogout,
	appRedirect,
	appBootGet,
	appUIErrorsShow,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
