import l from "lodash";
import he from "he";

class Tools {
	static buildUrlPath = (pairs) => {
		const url = [];
		Object.keys(pairs).forEach((key) => {
			url.push(`${key}/${pairs[key]}`);
		});
		return url.join("/");
	};

	static findAreaById = (id, areas) => {
		id = parseInt(id);
		let a = null;
		areas.forEach((area) => {
			if (area.id === id) a = area;
		});
		return a;
	};

	static convertString = (s) => {
		try {
			return he.decode(s);
		} catch (e) {
			return s;
		}
	};
}

export default Tools;
