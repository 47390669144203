import actionTypes from "../actionTypes";

export const authLocalLoginInit = (language, email, password) => {
	return {
		type: actionTypes.AUTH_LOCAL_LOGIN_INIT,
		payload: {
			language,
			email,
			password,
		},
	};
};

export const authSocialLoginInit = (language, data) => {
	return {
		type: actionTypes.AUTH_SOCIAL_LOGIN_INIT,
		payload: {
			language,
			provider: data.provider,
			token: data.token,
		},
	};
};

export const authLogout = usuario => {
	return {
		type: actionTypes.AUTH_LOGOUT,
		payload: usuario,
	};
};

export const authPerfilSaveInit = (language, data) => {
	return {
		type: actionTypes.AUTH_PERFIL_SAVE_INIT,
		payload: {
			language,
			data,
		},
	};
};

export const authLocalRegisterValidateInit = (language, data) => {
	return {
		type: actionTypes.AUTH_LOCAL_REGISTER_VALIDATE_INIT,
		payload: {
			language,
			data,
		},
	};
};

export const authLocalRegisterInit = (language, data) => {
	return {
		type: actionTypes.AUTH_LOCAL_REGISTER_INIT,
		payload: {
			language,
			data,
		},
	};
};

export const authPasswordRecoveryInit = (language, data) => {
	return {
		type: actionTypes.AUTH_PASSWORD_RECOVERY_INIT,
		payload: {
			language,
			data,
		},
	};
};

export const authPasswordChangeInit = (language, data) => {
	return {
		type: actionTypes.AUTH_PASSWORD_CHANGE_INIT,
		payload: {
			language,
			data,
		},
	};
};
