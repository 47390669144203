export const colors = {
	black01: "#000000",
	black02: "#111111",
	black03: "#191919",
	black04: "#252525",
	lightgray01: "#f0f0f0",
	lightgray02: "#c8c8c8",
	lightgray03: "#d7d7d7",
	lightgray04: "#525252",
	lightgray05: "#a0a0a0",
	lightgray06: "#646464",
	lightgray07: "#b7b7b7",
	lightgray08: "#8c8c8c",
	white: "#ffffff",
	blue: "#00274a",
	red: "#d70a00",
	ltblue: "#63a0f2",
	ltblue02: "#476b8a",
	ltblue03: "#1cd4ed",

	gray: "#e9e9e9",
	darkgray: "#8a8a8a",
};
