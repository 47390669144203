import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import { connect } from "react-redux";
import Tools from "../../classes/tools";

const PopupEmailVerification = ({ s }) => {
	const intl = useIntl();
	return (
		<article className="popup popup-email-verification show" style={{ display: "none" }}>
			<span className="close-popup overlay show-lg"></span>
			<div className="wrapper black-bg-03">
				<span className="close-popup close-button black-bg-01"></span>
				<div className="box">
					<h4 className="white-text prequel-font">{Tools.convertString(s.popups?.emailverification.title)}</h4>
					<br />
					<p className="white-text">
						{Tools.convertString(s.popups?.emailverification.line1)}
						<br />
						<br />
						{Tools.convertString(s.popups?.emailverification.line2)}
					</p>
				</div>
				<form action="" className="box">
					<button className="button blue-bg white-text">{Tools.convertString(s.popups?.emailverification.reenviar)}</button>
				</form>
			</div>
		</article>
	);
};

const mapStateToProps = (state) => {
	return {
		s: state.app.boot.strings,
	};
};

export default connect(mapStateToProps)(PopupEmailVerification);
