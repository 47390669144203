import styled, { css } from "styled-components";
import { sizes, mediaQueryMax, heights } from "../../assets/styles/size";
import { colors } from "../../assets/styles/colors";

export const StyledHeader = styled.header`
	padding: 0;
	height: auto;
	display: block;
	position: fixed;
	z-index: 9999;
	width: 100%;
	box-shadow: 0 2px 2px 0 rgba(94, 94, 94, 0.09);
	transition: box-shadow 0.3s ease-in-out;
	@media ${mediaQueryMax.lg0} {
		box-shadow: ${(props) => (props.scrolled ? "0 2px 2px 0 rgba(94, 94, 94, 0.09)" : "none")};
	}
`;

export const InnerContent = styled.div`
	max-width: ${sizes.xl};
	margin: auto;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
`;

export const Top = styled.div`
	position: relative;
	overflow: hidden;
	background-color: ${colors.white};
	padding: 0 90px;
	@media ${mediaQueryMax.lg0} {
		padding: 0 25px;
	}
	${InnerContent} {
		height: 30px;
	}
	nav {
		ul {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			li {
				margin: 0 25px;
				@media ${mediaQueryMax.xl} {
					margin: 0 15px;
				}
				&:first-child {
					margin-left: 0;
				}
				a {
					color: ${colors.black01};
					font-weight: 400;
					font-size: 14px;
					@media ${mediaQueryMax.xl} {
						font-size: 13px;
					}
				}
				&.logout {
					a {
						color: ${colors.red};
					}
				}
			}
		}
	}
	@media ${mediaQueryMax.lg0} {
		//display: none;
	}
`;

export const DropdownMenu = styled.div`
	margin-bottom: 24px;
	.dropdown-button {
		&:after {
			content: "";
			display: inline-block;
			width: 10px;
			height: 10px;
			border-bottom: 1px solid;
			border-left: 1px solid;
			transform: ${(props) =>
				props.opened ? "rotate(225deg) translate(0px, -4px) scaleX(-1)" : "rotate(-45deg) translate(4px, 0px)"};
			margin-left: 15px;
		}
	}
	ul {
		overflow: hidden;
		transition: max-height 0.3s ease-in-out, margin-top 0.3s ease-in-out;
		padding-left: 24px;
		max-height: ${(props) => (props.opened ? "99em" : 0)};
		// ${(props) => props.opened && "margin-top: 24px;"}
		li{
			margin: 24px 0 0 !important;
		}
	}
`;

export const LangButton = styled.button`
	padding: 0;
	margin: 0 0 0 10px;
	border: none;
	background: transparent;
	cursor: pointer;
	> div {
		width: 16px;
	}
	> img {
		width: 16px;
	}
`;

export const Bottom = styled.div`
	background-color: ${colors.black01};
	transition: background-color 0.3s ease-in-out;
	padding: 0 90px;
	@media ${mediaQueryMax.lg0} {
		padding: 0 25px;
		background-color: ${(props) => (props.scrolled || props.opened ? colors.black01 : "transparent")};
	}
	${InnerContent} {
		height: ${heights.headerHeight};
	}
`;

export const Logo = styled.div`
	a {
		display: block;
		position: relative;
		width: 100px;
		height: 36px;
		transition: all 0.3s ease-in-out;
		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
			&.normal {
				filter: invert(1) brightness(1.2);
				opacity: 1;
				visibility: visible;
			}
			&.mini {
				opacity: 0;
				visibility: hidden;
			}
		}
		@media ${mediaQueryMax.lg0} {
			img {
				&.normal {
					filter: ${(props) => (props.scrolled || props.opened ? "invert(1) brightness(1.2)" : "none")};
				}
			}
		}

		${(props) =>
			(props.scrolled || props.opened) &&
			css`
				width: 38px;
				height: 38px;
				img {
					&.normal {
						opacity: 0;
						visibility: hidden;
					}
					&.mini {
						opacity: 1;
						visibility: visible;
					}
				}
				@media ${mediaQueryMax.lg0} {
					width: 100px;
					height: 36px;
					img {
						&.normal {
							opacity: 1;
							visibility: visible;
						}
						&.mini {
							opacity: 0;
							visibility: hidden;
						}
					}
				}
			`};
	}
`;

export const Search = styled.div`
	margin-right: auto;
	margin-left: 30px;
	transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
	opacity: ${(props) => (props.scrolled ? 1 : 0)};
	visibility: ${(props) => (props.scrolled ? "visible" : "hidden")};
	form {
		display: flex;
		.formWrapper {
			width: 240px;
			display: flex;
			align-items: center;
			background: #fff;
			padding: 4px 15px;
			border-top-left-radius: 2px;
			border-bottom-left-radius: 2px;
			position: relative;
			.geosuggest {
				background: #fff;
				.geosuggest__suggests--hidden {
					max-height: 0;
					overflow: hidden;
					border-width: 0;
				}
				.geosuggest__suggests-wrapper {
					position: absolute;
					top: 37px;
					left: 0;
					z-index: 1;
					ul.geosuggest__suggests {
						background: white;
						li.geosuggest__item {
							padding: 4px 10px;
							cursor: pointer;
							&:hover {
								background: #eee;
							}
						}
					}
				}
			}

			@media (min-width: 1070px) {
				width: 328px;
			}

			svg {
				display: block;
				margin-right: 20px;
				width: 18px;
				path {
					fill: ${colors.blue};
				}
			}
			input {
				padding: 0;
				height: 100%;
				border: none;
				color: ${colors.blue};
				&::placeholder {
					color: ${colors.black03};
					font-size: 16px;
				}

				&::-webkit-input-placeholder {
					color: ${colors.black03};
					font-size: 16px;
				}

				&:-ms-input-placeholder {
					color: ${colors.black03};
					font-size: 16px;
				}

				&:-moz-placeholder {
					color: ${colors.black03};
					font-size: 16px;
				}

				&::-moz-placeholder {
					color: ${colors.black03};
					font-size: 16px;
				}
			}
		}
		button {
			width: auto;
			margin: 0;
			padding: 0 15px;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
`;

export const LoginBtn = styled.div`
	a {
		cursor: pointer;
		border: 1px solid ${colors.white};
		padding: 5px 25px;
		letter-spacing: 1px;
		font-size: 14px;
		font-weight: 500;
		display: inline-block;
	}
`;

export const MobileMenuBtn = styled.div`
	width: 48px;
	height: 30px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	z-index: 9;
	outline: transparent solid 1px;
	position: relative;
	cursor: pointer;

	span {
		display: block;
		width: 100%;
		height: 3px;
		background-color: ${(props) => (props.scrolled ? colors.white : colors.black01)};
		transition: all 0.3s ease-in-out;

		&.line-02 {
			width: 65%;
			margin-top: 12px;
		}
	}
	${(props) =>
		props.opened &&
		css`
			span {
				background-color: ${colors.white};
				position: absolute;
				transform: rotate(-45deg);
				top: 30%;

				&.line-02 {
					transform: rotate(45deg);
					width: 100%;
					top: -2px;
				}
			}
		`};
`;

export const MobileMenu = styled.div`
	position: fixed;
	background-color: ${colors.blue};
	top: ${heights.headerHeight};
	width: 100%;
	height: 100%;
	min-height: 100%;
	overflow: hidden;
	text-align: left;
	box-sizing: border-box;
	transition: right 0.3s ease-in-out, opacity 0.15s ease-in-out, visibility 0.15s ease-in-out;
	opacity: ${(props) => (props.opened ? 1 : 0)};
	visibility: ${(props) => (props.opened ? "visible" : "hidden")};
	right: ${(props) => (props.opened ? 0 : "-100%")};

	${(props) =>
		props.opened &&
		css`
			box-shadow: 2px 0 0 2px rgba(94, 94, 94, 0.09);
			visibility: visible;
		`};

	.menuMobileInner {
		padding: 35px 30px 40px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		nav {
			ul {
				li {
					margin: 0 0 24px;
					a {
						color: ${colors.white};
						font-weight: 400;
						font-size: 16px;
					}
					&.ig {
						a {
							color: ${colors.white};
							display: flex;
							align-items: center;

							&:before {
								content: "";
								display: inline-block;
								background: url("/img/ig.svg") no-repeat;
								background-size: 100%;
								width: 18px;
								height: 18px;
								margin-right: 15px;
							}
						}
					}
					&.login,
					&.logout {
						a {
							color: ${colors.white};
						}
					}
				}
			}
		}
	}

	${Search} {
		margin: 0;
		width: 100%;
		form {
			display: block;
			height: ${(props) => (props.scrolled ? "96px" : 0)};
			transition: height 0.3s ease-in-out;
			.formWrapper {
				width: 100%;
				padding: 10px 15px;
			}
			button {
				width: 100%;
				display: block;
				font-size: 16px;
				padding: 16px 30px;
				border-radius: 4px;
				min-width: 200px;
				text-align: center;
			}
		}
	}
	${LoginBtn} {
		margin-bottom: 24px;
	}
`;
